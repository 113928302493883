import { Injectable } from '@angular/core';

@Injectable()

export class BackEndUrlService {
  //backendUrl: string = "http://localhost:56562/";

  backendUrl: string = "https://api.theonlyawareness.org/";


  frontendUrl: string = "http://admin.theonlyawareness.org/";

  FileUploadUrl = this.backendUrl + "/FileUpload";

  constructor() { }

}
